import { mediaObservable } from "@feature/itmc/itmcPostModel";
import { backAction } from "@feature/itmc/postModelAction/backAction";
import { setPhotoAction } from "@feature/itmc/postModelAction/setPhotoAction";
import { setVideoAction } from "@feature/itmc/postModelAction/setVideoAction";
import { ITMCCaptureType, VideoMedia } from "@feature/itmc/types";
import useGlobalLoader from "@hooks/useGlobalLoader";
import useObservable from "@hooks/useObservable";
import { CameraDirection } from "@lib/models";
import { captureVideoFrame } from "@lib/util/captureVideoFrame";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import useEventCallback from "use-event-callback";
import Controls from "./Controls";
import MediaErrors from "./MediaErrors";
import useMediaHandler from "./useMediaHandler";

type VideoState = "querying" | "canplay";

export default function ITMCMediaRecorder() {
	const [videoState, setVideoState] = useState<VideoState>("querying");
	const [error, setError] = useState<Error | null>(null);
	const [cameraDirection, setCameraDirection] = useState<CameraDirection>("user");
	const [recording, setRecording] = useState(false);
	const recentMedia = useObservable(mediaObservable) ?? null;
	const [captureType, setCaptureType] = useState<ITMCCaptureType>("camera");

	useGlobalLoader(error === null && videoState === "querying", "requesting-camera-permissions");

	const onRecorded = useEventCallback((file: Blob) => {
		setVideoAction(file);
	});

	const onCanPlay = useEventCallback(() => {
		setVideoState("canplay");
	});

	const videoRef = useMediaHandler(cameraDirection, recording, onRecorded, onCanPlay, setError, captureType);

	useEffect(() => {
		if (recentMedia instanceof VideoMedia) {
			setCaptureType("video");
		}
	}, [recentMedia]);

	if (error != null) {
		return (
			<Box
				sx={{
					position: "absolute",
					inset: 0,
					width: "100%",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<MediaErrors error={error} onGoBack={backAction} />
			</Box>
		);
	}

	return (
		<>
			<Box
				component="video"
				ref={videoRef}
				disablePictureInPicture
				muted
				playsInline
				sx={{
					width: "100%",
					margin: "auto 0",
					zIndex: 0,
				}}
			/>
			{videoState === "canplay" ? (
				<Box
					sx={{
						position: "absolute",
						bottom: "40px",
						right: "16px",
						left: "16px",
						display: "flex",
						flexDirection: "column",
						justifyContent: "flex-end",
						gap: "26px",
					}}
				>
					<Controls
						captureType={captureType}
						recording={recording}
						onCaptureClick={() => {
							if (captureType === "video") {
								setRecording((recording) => !recording);
								return;
							}

							if (videoRef.current != null) {
								captureVideoFrame(videoRef)
									.then((blob) => {
										setPhotoAction(blob);
									})
									.catch((captureError) => {
										setError(captureError);
									});
							}
						}}
						onCaptureTypeChange={setCaptureType}
						onDirectionClick={() => {
							setCameraDirection((prev) => (prev === "user" ? "environment" : "user"));
						}}
						recentMedia={recentMedia}
					/>
				</Box>
			) : null}
		</>
	);
}
