import { LocationWithSource, PreciseLocationPermissionState } from "@lib/models";
import replay from "@lib/util/frp/replay";
import { fromEventPattern, map, Observable } from "rxjs";
import { geolocationErrorSubject } from "./preciseLocationError";

const continuousPositionOptions = {
	maximumAge: 30000, // allowed position cache time in ms. 30 seconds
	enableHighAccuracy: true,
};

// this checks the permissions but does not REQUEST them
export async function requestGeolocationPermission(): Promise<PreciseLocationPermissionState> {
	if ("geolocation" in navigator && !("permissions" in navigator)) {
		return "prompt";
	}
	if ("geolocation" in navigator && "permissions" in navigator) {
		try {
			return (await navigator.permissions.query({ name: "geolocation" })).state;
		} catch (err: unknown) {
			reportError(err as Error);
			return "denied";
		}
	}
	return "denied";
}

export const geoLocationObservable: Observable<LocationWithSource> = fromEventPattern<GeolocationPosition>(
	(handler) => {
		const watchId = navigator.geolocation.watchPosition(
			handler,
			(err: GeolocationPositionError) => {
				if (err.code !== GeolocationPositionError.PERMISSION_DENIED) {
					reportError(err);
				}
				geolocationErrorSubject.next(err);
			},
			continuousPositionOptions,
		);
		return watchId;
	},
	(_, watchId: number) => {
		navigator.geolocation.clearWatch(watchId);
	},
)
	.pipe(
		map(
			(geolocation): LocationWithSource => ({
				lng: geolocation.coords.longitude,
				lat: geolocation.coords.latitude,
				source: "browser_gps",
			}),
		),
	)
	.pipe(replay());

const browserGeolocation = {
	geoLocationObservable,
	getPermissions: requestGeolocationPermission,
};

export default browserGeolocation;
