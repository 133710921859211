import vcrOsdMono from "@lib/fonts/vcrOsdMono";
import { Box, Typography } from "@mui/material";
import constants from "@styles/constants";
import { t } from "i18next";
import BirthdateForm from "./BirthdateForm";

export default function AnonSignUp() {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				height: "100%",
				width: "100%",
				maxWidth: `${constants.dialogMaxWidth}px`,
				margin: "0px auto",
				padding: `${constants.headerHeight + 20}px 20px 40px`,
				"> p:first-of-type": { mb: "40px" },
				"& h2": {
					fontSize: "24px",
					margin: "0 0 16px 0",
					fontFamily: vcrOsdMono.style.fontFamily,
					padding: "0px",
					fontWeight: "400",
				},
			}}
		>
			<h2>{t("lurker_wizard.request_birthdate_title")}</h2>
			<Typography sx={{ fontSize: "16px" }}>{t("lurker_wizard.request_birthdate_helper")}</Typography>
			<BirthdateForm />
		</Box>
	);
}
