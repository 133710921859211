import { gpsLocationExplainerBehavior } from "@feature/map/GpsLocationExplainer/gpsLocationExplainerState";
import { LocationWithSource, PreciseLocationPermissionState } from "@lib/models";
import { routeObservable } from "@lib/Routes/routerModel";
import replay from "@lib/util/frp/replay";
import { matchPath } from "react-router-dom";
import { concat, defer, filter, from, map, merge, NEVER, Observable, switchMap, take } from "rxjs";
import browserGeolocation from "./browserGeolocation";
import { geolocationErrorSubject as watchPositionErrorObservable } from "./preciseLocationError";

interface PreciseLocationState {
	permissionState: PreciseLocationPermissionState;
	error: boolean;
}

const requestPermissionsRouteObservable = gpsLocationExplainerBehavior
	.pipe(
		switchMap((explainer) => {
			if (explainer === "seen") {
				return routeObservable;
			}
			return NEVER;
		}),
	)
	.pipe(map((location) => location.pathname))
	.pipe(
		filter((pathname) => {
			return matchPath(pathname, "/map") != null || matchPath(pathname, "/feed") != null;
		}),
	);

// TODO: switch with native wrapper
const { getPermissions, geoLocationObservable } = browserGeolocation;

const geoLocationWithTestEscape = defer(() => {
	if (localStorage.getItem("_debug_gps_error") === "true") {
		setTimeout(() => {
			const error = {
				code: GeolocationPositionError.POSITION_UNAVAILABLE,
				message: "Synthetically created geolocation error",
				PERMISSION_DENIED: 1,
				POSITION_UNAVAILABLE: 2,
				TIMEOUT: 3,
			} satisfies GeolocationPositionError;
			watchPositionErrorObservable.next(error);
		}, 500);
		return NEVER;
	}
	return geoLocationObservable;
});

const permissionCheckObservable = defer(() => from(getPermissions()))
	.pipe(
		map((permission) => ({
			permissionState: permission,
			error: false,
		})),
	)
	.pipe(replay());

const preciseLocationObservable: Observable<LocationWithSource> = merge(
	permissionCheckObservable
		.pipe(map(({ permissionState }) => permissionState))
		.pipe(filter((permissionState) => permissionState === "granted")),
	requestPermissionsRouteObservable,
)
	.pipe(map(() => true as const))
	.pipe(take(1))
	.pipe((obs) => concat(obs, geoLocationWithTestEscape))
	.pipe(filter((value): value is LocationWithSource => value !== true));

const preciseLocationStateObservable: Observable<PreciseLocationState> = concat(
	permissionCheckObservable,
	watchPositionErrorObservable.pipe(
		map((geolocationError): PreciseLocationState => {
			if (geolocationError.code === GeolocationPositionError.PERMISSION_DENIED) {
				return {
					permissionState: "denied",
					error: false,
				};
			}
			return {
				permissionState: "granted",
				error: true,
			};
		}),
	),
).pipe(replay());

export default {
	preciseLocationStateObservable,
	preciseLocationObservable,
};
