import { useEffect, useState } from "react";
import { BehaviorSubject, Observable } from "rxjs";

function useObservable<T>(observable: BehaviorSubject<T>): T;
function useObservable<T>(observable: Observable<T>): T | undefined;
function useObservable<T>(observable: Observable<T>, initialValue: T): T;
function useObservable<T>(observable: Observable<T> | BehaviorSubject<T>, initialValue?: T) {
	type R = typeof observable extends BehaviorSubject<T> ? T : T | undefined;

	const [value, setValue] = useState<R>(() => {
		if (observable instanceof BehaviorSubject) {
			return observable.value as T;
		}
		return initialValue;
	});

	useEffect(() => {
		const subscription = observable.subscribe((value) => {
			setValue(value);
		});
		return () => {
			subscription.unsubscribe();
		};
	}, [observable]);

	return value;
}

export default useObservable;
