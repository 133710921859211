import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { updateRouterModel } from "./routerModel";

export default function RouteListener() {
	const location = useLocation();

	useEffect(() => {
		updateRouterModel(location);
	}, [location]);

	return null;
}
