import { Button } from "@components/Button";
import LocationPinIcon from "@components/icons/LocationPinIcon";
import vcrOsdMono from "@lib/fonts/vcrOsdMono";
import { Box, Typography } from "@mui/material";
import constants from "@styles/constants";
import palette from "@styles/palette";
import { useTranslation } from "next-i18next";
import { setGpsLocationExplainerState } from "./GpsLocationExplainer/gpsLocationExplainerState";

export default function GpsLocationExplainer() {
	const { t } = useTranslation();

	const handleClick = () => {
		setGpsLocationExplainerState("seen");
	};

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				height: "100%",
				width: "100%",
				maxWidth: `${constants.dialogMaxWidth}px`,
				margin: "0 auto",
				padding: `${constants.headerHeight + 20}px 20px 40px`,
				gap: "20px",
				"& h2": {
					fontSize: "24px",
					margin: 0,
					fontFamily: vcrOsdMono.style.fontFamily,
					padding: "0px",
					fontWeight: "400",
				},
			}}
		>
			<h2>{t("gps_location_explainer.title")}</h2>

			<Typography>{t("gps_location_explainer.subtitle")}</Typography>

			<LocationPinIcon sx={{ color: palette.Primary, width: "160px", height: "160px", margin: "auto" }} />

			<Button variant="primary" size="lg" sx={{ width: "100%" }} onClick={handleClick}>
				{t("gps_location_explainer.button_title")}
			</Button>
		</Box>
	);
}
