import AppLayout from "@components/AppLayout";
import AlertContainer from "@components/AppLayout/AlertContainer";
import { AppLayoutAlert } from "@components/AppLayout/appAlert";
import useFeatureGate from "@feature/featureFlags/useFeatureGate";
import LocationInaccurateToast from "@feature/location/LocationInaccurateToast";
import { locationObservable } from "@feature/location/locationModel";
import { locationStatusObservable } from "@feature/location/locationStatusModel";
import useIsAnon from "@hooks/useIsAnon";
import useObservable from "@hooks/useObservable";
import useRegionBlocked from "@hooks/useRegionBlocked";
import { Box } from "@mui/material";
import constants from "@styles/constants";
import { useMatch } from "react-router-dom";
import AnonMapToast from "../anon/AnonMapToast";
import NotificationViewer from "../notification/NotificationViewer";
import GpsErrorView from "./GpsErrorView";
import GpsLocationExplainer from "./GpsLocationExplainer";
import { gpsLocationExplainerBehavior } from "./GpsLocationExplainer/gpsLocationExplainerState";
import IPLocationOverThresholdView from "./IPLocationOverThresholdView";
import LoadingMapView from "./LoadingMapView";
import MapView from "./MapView";
import PreciseLocation from "./PreciseLocation";
import RegionBlockedMapView from "./RegionBlockedMapView";
import useMapStatus from "./useMapStatus";

export default function MapLayout() {
	const mapRendered = useMapStatus();
	const isAnon = useIsAnon();
	const isMapRoute = useMatch("/map") != null;
	const isRegionBlocked = useRegionBlocked();
	const newLocationEnabled = useFeatureGate("location_accuracy");
	const locationStatus = useObservable(locationStatusObservable);
	const ownLocation = useObservable(locationObservable, null);
	const hasLocation = ownLocation != null && locationStatus?.hasLocation;
	const showIPLocationOverThreshold =
		newLocationEnabled &&
		!hasLocation &&
		locationStatus?.ipLocationOverThreshhold &&
		locationStatus?.precisePermissionState == "denied";
	const showGPSError = newLocationEnabled && locationStatus?.error;
	const showLoadingView = newLocationEnabled && !hasLocation;
	const gpsExplainerState = useObservable(gpsLocationExplainerBehavior);

	if (newLocationEnabled && gpsExplainerState !== "seen") {
		return <GpsLocationExplainer />;
	}

	return (
		<AppLayout noGlobalAlert>
			<Box
				component="main"
				sx={{
					flex: "1 1 auto",
					display: "flex",
					flexDirection: "column",
					alignItems: "stretch",
					justifyContent: "stretch",
				}}
			>
				<Box sx={{ position: "absolute", inset: 0, bottom: constants.footerHeight }}>
					{(() => {
						if (isRegionBlocked) {
							return <RegionBlockedMapView />;
						}
						if (showIPLocationOverThreshold) {
							return <IPLocationOverThresholdView />;
						}
						if (showGPSError) {
							return <GpsErrorView />;
						}
						if (showLoadingView) {
							return <LoadingMapView />;
						}
						return <MapView />;
					})()}
				</Box>
				{showLoadingView ? null : (
					<AlertContainer sx={{ gap: "20px", display: "flex", flexDirection: "column" }}>
						<AppLayoutAlert />
						{(() => {
							if (isAnon) {
								return <AnonMapToast />;
							}
							if (mapRendered && isAnon === false) {
								return <NotificationViewer />;
							}
							return null;
						})()}
						{isMapRoute && !isRegionBlocked && !newLocationEnabled ? <PreciseLocation /> : null}
						{newLocationEnabled && ownLocation?.source === "ip" ? <LocationInaccurateToast /> : null}
					</AlertContainer>
				)}
			</Box>
		</AppLayout>
	);
}
