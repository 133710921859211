import { LocationSource, UserAccount, UserSettings, UserShort } from "@lib/models";
import { AxiosError } from "axios";
import { deleteRequest, get, patch, post } from "../httpClient";
import { ServerFormError } from "./commonErrors";

type UpdateProfileRequest = {
	display_name?: string;
	about_me?: string;
	languages_spoken?: string[];
	birthdate?: string;
	show_age?: boolean;
	height?: string | null;
	safety?: string | null;
	hiv_status?: string | null;
	sexualities?: string[];
	gender_identities?: string[];
	vaccinations?: string[];
	relationships?: string[];
	my_roles?: string[];
	my_identities?: string[];
	my_traits?: string[];
	looking_for_roles?: string[];
	looking_for_identities?: string[];
	looking_for_traits?: string[];
	scenarios?: string[];
	into?: string[];
	kinks?: string[];
	pronouns?: string[];
};
type UpdateProfileResponse = UserAccount;

const updateProfilePath = "/user/profile";
export async function updateProfile(body: UpdateProfileRequest): Promise<UpdateProfileResponse> {
	return post<UpdateProfileRequest, UpdateProfileResponse>(updateProfilePath, body).catch((error) => {
		if (error instanceof AxiosError && error.response?.status === 422) {
			if (typeof error.response.data === "object") {
				// translate server field names sexuality -> sexualities and gender_identity -> gender_identities
				// for blocklist errors
				const fields = Object.fromEntries(
					Object.entries(error.response.data.fields).map(([fieldName, errors]) => {
						if (fieldName === "sexuality") {
							return ["sexualities", errors];
						}
						if (fieldName === "gender_identity") {
							return ["gender_identities", errors];
						}
						return [fieldName, errors];
					}),
				);
				throw new ServerFormError({ ...error.response.data, fields });
			}
		}
		throw error;
	});
}

type AddProfilePictureRequest = {
	profile_photo: {
		image: {
			id: string;
			metadata: {
				crop: {
					x: number;
					y: number;
					width: number;
					height: number;
				};
			};
		};
		pinned?: boolean;
	};
};

type AddProfilePictureResponse = void;

export async function addProfilePicture(body: AddProfilePictureRequest): Promise<AddProfilePictureResponse> {
	return post<AddProfilePictureRequest, AddProfilePictureResponse>("/profile_photos", body).catch((error) => {
		if (error instanceof AxiosError && error.response?.status === 422) {
			if (typeof error.response.data === "object") {
				throw new ServerFormError(error.response.data);
			}
		}
		throw error;
	});
}

type DeleteProfilePictureRequest = {
	uuid: string;
};
type DeleteProfilePictureResponse = void;

export async function deleteProfileImage(body: DeleteProfilePictureRequest): Promise<DeleteProfilePictureResponse> {
	return deleteRequest<DeleteProfilePictureRequest, DeleteProfilePictureResponse>("/profile_photos", body);
}

type UpdatePinnedPictureRequest = {
	uuid: string;
	pinned: boolean;
};

export async function updatePinnedPicture(body: UpdatePinnedPictureRequest): Promise<void> {
	return patch("/profile_photos", body);
}

export type AddInTheMomentStatusRequest = {
	in_the_moment_status: {
		message?: string;
		// image and video are mutually exclusive
		image?: {
			id: string;
			metadata: {
				crop: {
					x: number;
					y: number;
					width: number;
					height: number;
				};
			};
		};
		video?: {
			id: string;
		};
	};
};
type AddInTheMomentStatusResponse = void;

export async function addInTheMomentStatus(body: AddInTheMomentStatusRequest): Promise<AddInTheMomentStatusResponse> {
	return post<AddInTheMomentStatusRequest, AddInTheMomentStatusResponse>("/in_the_moment_statuses", body).catch(
		(error) => {
			if (error instanceof AxiosError && error.response?.status === 422) {
				if (typeof error.response.data === "object") {
					throw new ServerFormError(error.response.data);
				}
			}
			throw error;
		},
	);
}

type DeleteInTheMomentStatusResponse = void;

export async function deleteInTheMomentStatus(): Promise<DeleteInTheMomentStatusResponse> {
	return deleteRequest<undefined, DeleteInTheMomentStatusResponse>("/in_the_moment_statuses", undefined);
}

type UpdateLocationRequest = {
	lat: number;
	lng: number;
	source: LocationSource | "default";
};

export async function updateLocation(body: UpdateLocationRequest): Promise<undefined> {
	return post<UpdateLocationRequest, undefined>("/user/location", body);
}

export async function clearLocation(): Promise<undefined> {
	return deleteRequest("/user/location");
}

export type UpdateLocationRandomizationRequest = {
	location_randomization: number;
	lat?: number;
	lng?: number;
	source?: LocationSource;
};

export async function updateLocationRandomization(body: UpdateLocationRandomizationRequest) {
	return patch<UpdateLocationRandomizationRequest, undefined>("/user/location_randomization", body);
}

export const userSettingsPath = "/user/settings";
export async function getUserSettings() {
	return get<unknown, UserSettings | Record<string, never>>(userSettingsPath);
}

type UserSettingsRequest = { account_settings: Partial<UserSettings> };
export async function postUserSettings(body: UserSettingsRequest) {
	return post<UserSettingsRequest, void>(userSettingsPath, body);
}

export const blockedUsersPath = "/user/blocks";

export async function getBlockedUsers(path: string): Promise<UserShort[]> {
	return get(path);
}
