import { accountRegisterObservable } from "@feature/accountRegisterModel";
import type { FeatureGateName } from "@feature/featureFlags/statsigGateTypes";
import { AccountRegister } from "@lib/models";
import assertType from "@lib/util/assertType";
import replay from "@lib/util/frp/replay";
import { DynamicConfig, StatsigClient, StatsigEvent } from "@statsig/js-client";
import {
	BehaviorSubject,
	distinctUntilChanged,
	filter,
	firstValueFrom,
	from,
	map,
	Observable,
	of,
	retry,
	switchMap,
	take,
} from "rxjs";

const statsigClientBehavior = new BehaviorSubject<StatsigClient | null>(null);

function initializeStatsigClientObservable(injectedAccountRegisterObservable: Observable<AccountRegister>) {
	return injectedAccountRegisterObservable
		.pipe(take(1))
		.pipe(
			map(({ user: ownUser, flags }) => {
				return { userID: ownUser?.uuid, country: flags.derived_country_code ?? undefined };
			}),
		)
		.pipe(
			map(async (statsigUser) => {
				const statsigClient = new StatsigClient(assertType(process.env.NEXT_PUBLIC_STATSIG_SDK_KEY), statsigUser, {
					networkConfig: {
						api: process.env.NEXT_PUBLIC_STATSIG_PROXY,
					},
					environment: { tier: process.env.NEXT_PUBLIC_ENVIRONMENT },
				});
				await statsigClient.initializeAsync();
				return statsigClient;
			}),
		)
		.pipe(switchMap((statsigInitPromise) => from(statsigInitPromise)))
		.pipe(retry(1))
		.pipe(replay());
}

export const statsigReadyObservable = statsigClientBehavior
	.pipe(filter((statsigClient) => statsigClient !== null))
	.pipe(map((): void => undefined))
	.pipe(distinctUntilChanged());

export async function testOnlyInitializeStatig(accountRegister: AccountRegister) {
	initializeStatsigClientObservable(of(accountRegister)).subscribe((client) => {
		statsigClientBehavior.next(client);
	});

	return firstValueFrom(statsigReadyObservable);
}

if (process.env.NODE_ENV !== "test" && typeof window !== "undefined") {
	initializeStatsigClientObservable(accountRegisterObservable).subscribe((client) => {
		statsigClientBehavior.next(client);
	});
}

export function logFeatureEvent(event: StatsigEvent) {
	const syncStatsigClient = statsigClientBehavior.value;
	if (syncStatsigClient == null) {
		statsigClientBehavior
			.pipe(filter((client) => client != null))
			.pipe(take(1))
			.subscribe((statsigClient) => {
				statsigClient.logEvent(event);
			});
	} else {
		syncStatsigClient.logEvent(event);
	}
}

/**
 * This sends a tracking signal to statsig for every call. You can use useFeatureGate to only
 * signal once on mount.
 */
export function checkFeatureGate(gateName: FeatureGateName): boolean {
	const statsigClient = statsigClientBehavior.value;
	if (statsigClient == null) {
		throw new Error(`Statsig not yet initialized for feature gate check: "${gateName}"!`);
	}
	return statsigClient.checkGate(gateName);
}

/**
 * This sends a tracking signal to statsig for every call. You can use useConfig to only
 * signal once on mount.
 */
export function getConfig(configName: string) {
	const statsigClient = statsigClientBehavior.value;
	if (statsigClient == null) {
		throw new Error(`Statsig not yet initialized to retrieve dynamic config: "${configName}"!`);
	}
	return statsigClient.getDynamicConfig(configName);
}

export function featureGateObservable(gateName: FeatureGateName): Observable<boolean> {
	return statsigReadyObservable.pipe(map(() => checkFeatureGate(gateName)));
}

export function configObservable(configName: string): Observable<DynamicConfig> {
	return statsigReadyObservable.pipe(map(() => getConfig(configName)));
}
