import useCurrentNotification from "./useCurrentNotification";

export default function NotificationViewer() {
	const currentNotification = useCurrentNotification();

	if (currentNotification == null) {
		return null;
	}

	return <currentNotification.Message onClose={currentNotification.onClose} />;
}
