import { getLocale } from "@hooks/useLocale";
import * as Sentry from "@sentry/react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import routeList from "./routeList";

// This file can't be tested, as it needs the browser for createBrowserRouter
// The important to test parts are extracted into routeList and tested separately.

export default (() => {
	if (typeof window === "undefined") {
		return function NullComponent() {
			return null;
		};
	}

	const basepath = "/" + getLocale();

	const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

	const router = sentryCreateBrowserRouter(routeList, {
		basename: basepath,
		future: {
			v7_relativeSplatPath: true,
			v7_fetcherPersist: true,
			// Normalize `useNavigation()`/`useFetcher()` `formMethod` to uppercase
			v7_normalizeFormMethod: true,
			v7_partialHydration: true,
			v7_skipActionErrorRevalidation: true,
		},
	});

	return function Routes() {
		return (
			<RouterProvider
				router={router}
				future={{
					v7_startTransition: true,
				}}
			/>
		);
	};
})();
