import { Button } from "@components/Button";
import InformationPane from "@components/InformationPane";
import Link from "@components/Link";
import GlobeIcon from "@components/icons/GlobeIcon";
import RefreshIcon from "@components/icons/RefreshIcon";
import useLocale from "@hooks/useLocale";
import getSupportLinks from "@lib/util/getSupportLinks";
import { locationReload } from "@lib/util/locationWrapper";
import { Box, Typography } from "@mui/material";
import palette from "@styles/palette";
import { Trans, useTranslation } from "next-i18next";

export default function GpsErrorView() {
	const { t } = useTranslation("common");
	const locale = useLocale();
	const externalLink = getSupportLinks("faq", locale);

	return (
		<Box
			sx={{
				position: "absolute",
				inset: 0,
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
			}}
		>
			<InformationPane
				Icon={GlobeIcon}
				title={t("gps_error_map_pane.title")}
				subtitle={
					<Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
						<Typography sx={{ textAlign: "center", fontSize: "16px" }}>{t("gps_error_map_pane.subtitle")}</Typography>
						<Typography sx={{ textAlign: "center", fontSize: "16px" }}>
							<Trans
								t={t}
								i18nKey={"gps_error_map_pane.help_link"}
								components={[
									<Link
										to={externalLink}
										sx={{
											color: palette.onSurface,
											textDecoration: `underline ${palette.onSurface}`,
										}}
										target="_blank"
										key="0"
									/>,
								]}
							/>
						</Typography>
						<Button variant="primary" size="lg" sx={{ width: "100%" }} onClick={locationReload}>
							<RefreshIcon />
							{t("reload_button")}
						</Button>
					</Box>
				}
			/>
		</Box>
	);
}
