import { accountRegisterErrorObservable, accountRegisterObservable } from "@feature/accountRegisterModel";
import useGlobalLoader from "@hooks/useGlobalLoader";
import useObservable from "@hooks/useObservable";
import { ReactNode } from "react";
import { catchError, map, of } from "rxjs";
import { statsigReadyObservable } from "./featureFlags/featureConfigModel";

const statsigClientStatusObservable = statsigReadyObservable
	.pipe(map(() => true))
	.pipe(catchError((error: Error) => of(error)));

export default function InitialDataLoader({ children }: { children: ReactNode }) {
	const accountRegister = useObservable(accountRegisterObservable);
	const accountRegisterError = useObservable(accountRegisterErrorObservable);
	const statsigReadyOrError = useObservable(statsigClientStatusObservable);

	const loadingState = accountRegister == null || statsigReadyOrError == null;
	useGlobalLoader(loadingState, "initial data loader");

	if (accountRegisterError != null && accountRegister == null) {
		throw accountRegisterError;
	}

	if (statsigReadyOrError instanceof Error) {
		throw statsigReadyOrError;
	}

	if (loadingState) {
		return null;
	}

	return children;
}
