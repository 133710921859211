import GlobalFeed from "@feature/Feed/GlobalFeed";
import ITMC from "@feature/itmc";
import GlobalMap from "@feature/map/GlobalMap";
import { Outlet } from "react-router-dom";
import RouteListener from "./RouteListener";

export default function RootComponent() {
	return (
		<>
			<RouteListener />
			<GlobalMap />
			<Outlet />
			<GlobalFeed />
			{/* TODO: replace with global modal component when adding more modals*/}
			<ITMC />
		</>
	);
}
