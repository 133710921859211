import { ReactNode } from "react";
import AlertContainer from "./AlertContainer";
import BottomNav from "./BottomNav";
import TopNav from "./TopNav";
import { AppLayoutAlert } from "./appAlert";

type HomeButtonTarget = "back" | "map";

type AppLayoutProps = {
	children: ReactNode | null;
	homeButtonTarget?: HomeButtonTarget;
	pageHeading?: ReactNode | undefined;
	toolbarHead?: ReactNode | undefined;
	noBottomNavigation?: boolean;
	noGlobalAlert?: boolean;
};

export default function AppLayout({
	children,
	homeButtonTarget,
	pageHeading,
	toolbarHead,
	noBottomNavigation,
	noGlobalAlert,
}: AppLayoutProps) {
	return (
		<>
			<TopNav homeButtonTarget={homeButtonTarget} pageHeading={pageHeading} toolbarHead={toolbarHead} />
			{noGlobalAlert ? null : (
				<AlertContainer>
					<AppLayoutAlert />
				</AlertContainer>
			)}
			{children}
			{noBottomNavigation === true ? null : <BottomNav />}
		</>
	);
}
