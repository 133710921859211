import useConfig from "@feature/featureFlags/useConfig";
import { MapboxOptions } from "mapbox-gl";
import { useMemo } from "react";
import mapConfig from "./mapConfig";

export default function useMapboxConfig(): MapboxOptions & { zoom: number } {
	const statsigMapboxConfig = useConfig("mapbox_config").value ?? {};
	const stringyConfig = JSON.stringify({ ...mapConfig, ...statsigMapboxConfig });
	return useMemo(() => JSON.parse(stringyConfig), [stringyConfig]);
}
