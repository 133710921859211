import { Button } from "@components/Button";
import Dialog from "@components/Dialog";
import ArrowUpRightIcon from "@components/icons/ArrowUpRightIcon";
import useLocale from "@hooks/useLocale";
import getSupportLinks from "@lib/util/getSupportLinks";
import { DialogActions, Typography } from "@mui/material";
import { useTranslation } from "next-i18next";
import { ReactNode, useState } from "react";

const AnchorButton = Button.withComponent("a");

type LocationInaccurateModalProps = {
	renderButton: (onOpen: () => void) => ReactNode;
};

export default function LocationInaccurateModal({ renderButton }: LocationInaccurateModalProps) {
	const { t } = useTranslation("common");
	const [open, setOpen] = useState(false);
	const locale = useLocale();

	function onClose() {
		setOpen(false);
	}

	return (
		<>
			{renderButton(() => {
				setOpen(true);
			})}
			<Dialog title={t("location_request.location_inaccurate_modal_title")} open={open} onClose={onClose}>
				<Typography>{t("location_request.location_inaccurate_modal_body")}</Typography>
				<DialogActions>
					<AnchorButton variant="primary" href={getSupportLinks("faq", locale)} target="_blank">
						{t("location_request.location_inaccurate_modal_button")}
						<ArrowUpRightIcon />
					</AnchorButton>
				</DialogActions>
			</Dialog>
		</>
	);
}
