import { accountRegisterObservable } from "@feature/accountRegisterModel";
import { LocationWithSource } from "@lib/models";
import replay from "@lib/util/frp/replay";
import { distinctUntilChanged, map, Observable, of, switchMap } from "rxjs";

const IP_ACCURACY_THRESHOLD = 50; // kilometers

export const ipOverThresholdObservable = accountRegisterObservable
	.pipe(
		map((accountRegister) => {
			const debugOverride = localStorage.getItem("_debug_ip_over_threshold");
			if (debugOverride != null) {
				return debugOverride === "true";
			}
			return (accountRegister.flags.ip_location_accuracy ?? 0) > IP_ACCURACY_THRESHOLD;
		}),
	)
	.pipe(distinctUntilChanged());

const rawIPLocationObservable: Observable<LocationWithSource | null> = accountRegisterObservable
	.pipe(map((accountRegister) => accountRegister.flags.ip_geolocated_location))
	.pipe(map((location) => (location == null ? null : { ...location, source: "ip" as const })))
	// don't need to compare `source`, it will always equal "ip"
	.pipe(distinctUntilChanged((prev, next) => prev === next || (prev?.lng === next?.lng && prev?.lat === next?.lat)))
	.pipe(replay());

export const ipLocationObservable: Observable<LocationWithSource | null> = ipOverThresholdObservable.pipe(
	switchMap((overThreshold) => {
		if (overThreshold) {
			return of(null);
		}
		return rawIPLocationObservable;
	}),
);
