import Alert from "@components/Alert";
import useLocale from "@hooks/useLocale";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Link } from "@mui/material";
import { useTranslation } from "next-i18next";
import { SurveyNotificationMessageProps } from "../types";

const npsSurveyLink = "https://www.surveymonkey.com/r/TZC63N8?lang=";
const bkdrAug2024HiringLink = "https://grnh.se/7368ad594us";

function ToastMessage({
	title,
	surveyLink,
	localeSpecific,
	onClose,
}: { title: string; surveyLink: string; localeSpecific: boolean } & SurveyNotificationMessageProps) {
	const { t } = useTranslation("common");
	const locale = useLocale();
	const href = localeSpecific ? surveyLink + locale : surveyLink;

	return (
		<Alert
			title={title}
			body={
				<Link
					target="_blank"
					href={href}
					onClick={() => {
						onClose(true);
					}}
					sx={{
						overflowWrap: "break-word",
					}}
				>
					{href} <OpenInNewIcon fontSize="inherit" sx={{ verticalAlign: "text-bottom" }} />
					<span className="sr-only">{t("new_tab_link_screen_reader_text")}</span>
				</Link>
			}
			onClose={() => {
				onClose(false);
			}}
		/>
	);
}

export function NPSSurveyAvailableMessage({ onClose }: SurveyNotificationMessageProps) {
	const { t } = useTranslation("common");

	return (
		<ToastMessage
			title={t("survey_banner.banner_title")}
			surveyLink={npsSurveyLink}
			localeSpecific={true}
			onClose={onClose}
		/>
	);
}

export function BKDRAug2024HiringLink({ onClose }: SurveyNotificationMessageProps) {
	const { t } = useTranslation("common");

	return (
		<ToastMessage
			title={t("hiring_banner.banner_title")}
			surveyLink={bkdrAug2024HiringLink}
			localeSpecific={false}
			onClose={onClose}
		/>
	);
}
