import { Location } from "react-router-dom";
import { Observable, ReplaySubject } from "rxjs";

const routeSubject = new ReplaySubject<Location>(1);

export function updateRouterModel(location: Location) {
	routeSubject.next(location);
}

export const routeObservable: Observable<Location> = routeSubject;
