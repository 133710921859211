import HeaderBar from "@components/HeaderBar";
import RadiusSelector from "@lib/feature/Feed/RadiusSelector";
import { Box, styled } from "@mui/material";
import { ReactNode } from "react";
import { Link, useMatch } from "react-router-dom";
import AppMenu from "./AppMenu";
import BKDRHeaderLogo from "./BKDRHeaderLogo";

const H1 = styled("h1")({
	margin: "0px auto 0px 0px",
});

type HomeButtonTarget = "back" | "map";

type TopNavProps = {
	homeButtonTarget?: HomeButtonTarget;
	pageHeading?: ReactNode | undefined;
	toolbarHead?: ReactNode | undefined;
};

export default function TopNav({ homeButtonTarget, pageHeading, toolbarHead }: TopNavProps) {
	const isMapView = useMatch("/map") != null;
	const isFeedRoute = useMatch("/feed") != null;

	const shouldShowRadiusMenu = isFeedRoute;

	// if any HeaderBar related props are passed, render that instead
	if (homeButtonTarget != null || pageHeading != null || toolbarHead != null) {
		return (
			<HeaderBar
				backButtonTarget={homeButtonTarget}
				heading={pageHeading}
				customHeader={toolbarHead}
				onBack={undefined}
			/>
		);
	}

	return (
		<Box
			component="header"
			sx={{
				backgroundColor: "transparent",
				display: "flex",
				alignItems: "center",
				gap: "4px",
				zIndex: isMapView ? 1 : 0,
				pointerEvents: isMapView ? "none" : "auto",
				pt: "env(safe-area-inset-top, 0)",

				"> *": {
					pointerEvents: "auto",
				},
			}}
		>
			<H1>
				{isMapView ? (
					<Box sx={{ display: "flex", alignItems: "end" }}>
						<BKDRHeaderLogo />
					</Box>
				) : (
					<Link to="/map" style={{ display: "flex", alignItems: "end" }}>
						<BKDRHeaderLogo />
					</Link>
				)}
			</H1>
			{isMapView ? (
				<Box sx={{ height: "44px", overflow: "hidden", display: "flex" }}>
					<AppMenu />
				</Box>
			) : null}
			{shouldShowRadiusMenu ? <RadiusSelector /> : null}
		</Box>
	);
}
