import { getConfig } from "@feature/featureFlags/featureConfigModel";
import mapboxgl, { LngLatBounds } from "mapbox-gl";
import {
	BehaviorSubject,
	Observable,
	ReplaySubject,
	combineLatest,
	distinctUntilChanged,
	filter,
	fromEvent,
	map,
	share,
	startWith,
	switchMap,
	throttleTime,
} from "rxjs";
import getClustersFromPoints, { FlyToPoint, SuperclusterConfig } from "../clustering/getClustersFromPoints";
import { Pin } from "../pin";
import { ChunkBounds } from "./chunks";
import chunksFromBounds from "./chunksFromBounds";
import mapChunkBoundsToFriendChunkMap from "./mapChunkBoundsToFriendChunkMap";
import staggerNewPins from "./staggerNewPins";
import toFriendPins from "./toFriendPins";

const MOVE_THROTTLE = 500;

const DEFAULT_CLUSTER_CONFIG = { radius: 35, maxZoom: 11 } as SuperclusterConfig;

export default function pinsLoaderSetup(
	mapbox: mapboxgl.Map,
	setPins: (pins: Pin[]) => void,
	onError: (error: Error) => void,
	clusteringEnabledBehaviorSubject: BehaviorSubject<boolean>,
) {
	const clusterConfig = (getConfig("cluster_config").value ?? DEFAULT_CLUSTER_CONFIG) as SuperclusterConfig;

	function onClusterClick(flyToPoint: FlyToPoint) {
		mapbox.flyTo(flyToPoint);
	}

	const eventListenerStream = fromEvent<Event>(mapbox, "move")
		.pipe(throttleTime(MOVE_THROTTLE, undefined, { leading: true, trailing: true }))
		.pipe(startWith(undefined))
		// all subscribers subscribe to the same instance
		.pipe(share());

	const chunkBoundsStream = eventListenerStream
		.pipe(map(() => mapbox.getBounds()))
		.pipe(filter((bounds): bounds is LngLatBounds => bounds != null))
		.pipe(map((bounds) => chunksFromBounds(mapbox.getCenter(), bounds)))
		.pipe(distinctUntilChanged((a, b) => a.equalTo(b)))
		.pipe(share({ connector: () => new ReplaySubject<ChunkBounds>(1) }));

	const zoomStream = eventListenerStream.pipe(map(() => mapbox.getZoom())).pipe(distinctUntilChanged());

	const friendPinsStream = chunkBoundsStream.pipe(mapChunkBoundsToFriendChunkMap).pipe(toFriendPins(chunkBoundsStream));

	const outputStream = clusteringEnabledBehaviorSubject
		.pipe(
			switchMap((enabled) =>
				enabled
					? combineLatest([zoomStream, friendPinsStream, chunkBoundsStream]).pipe(
							map(([zoom, friendPins, chunkBounds]) => {
								return getClustersFromPoints(friendPins, zoom, chunkBounds, onClusterClick, clusterConfig);
							}),
						)
					: (friendPinsStream as Observable<Pin[]>),
			),
		)
		// load pins into map piecewise over time
		.pipe(staggerNewPins);

	const subscription = outputStream.subscribe({
		next(pins) {
			setPins(pins);
		},
		error: onError,
	});

	return () => {
		subscription.unsubscribe();
	};
}
