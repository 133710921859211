import makeLocalStorageHelper from "@lib/util/makeLocalStorageHelper";
import { BehaviorSubject } from "rxjs";

export type GpsLocationExplainerState = { state: "not seen" | "seen" };
export type GpsLocationExplainerStateOption = GpsLocationExplainerState["state"];

const gpsLocationExplainerKey = "/feature/gps_location/explainer";

const { get, set } = makeLocalStorageHelper<GpsLocationExplainerState>(gpsLocationExplainerKey);

function getGpsLocationExplainerState(): GpsLocationExplainerStateOption {
	return get()?.state ?? "not seen";
}

export function setGpsLocationExplainerState(state: GpsLocationExplainerStateOption) {
	set({ state });
	gpsLocationExplainerBehavior.next(state);
}

export const gpsLocationExplainerBehavior = new BehaviorSubject<GpsLocationExplainerStateOption>("not seen");

if (typeof window !== "undefined") {
	gpsLocationExplainerBehavior.next(getGpsLocationExplainerState());
}
