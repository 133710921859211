import { locationObservable } from "@feature/location/locationModel";
import { getMap } from "@lib/feature/map/mapbox";
import mapConfig from "@lib/feature/map/mapbox/mapConfig";
import { LocationWithNullableSource } from "@lib/models";
import distanceFrom from "distance-from";
import { filter, map, pairwise } from "rxjs";

const THRESHOLD = 0.5; // miles

export default function subscribeToUpdateMapCenterFromLocationChange() {
	const subscription = locationObservable
		.pipe(
			pairwise(),
			filter((prevAndCurr): prevAndCurr is [LocationWithNullableSource, LocationWithNullableSource] => {
				const [prev, curr] = prevAndCurr;

				return (
					prev != null &&
					curr != null &&
					distanceFrom([prev.lat, prev.lng]).to([curr.lat, curr.lng]).in("miles") >= THRESHOLD
				);
			}),
			map(([, curr]) => curr),
		)
		.subscribe((location) => {
			getMap()?.easeTo({ center: [location.lng, location.lat], zoom: mapConfig.maxZoom });
		});

	return () => {
		subscription.unsubscribe();
	};
}
