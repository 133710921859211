import palette from "@styles/palette";

export const _BKDR = (
	<svg width="113" height="44" viewBox="0 0 113 44" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect width="112" height="44" transform="translate(0.989258)" fill={palette.Primary} />
		<path
			d="M19.1572 31.4292V25.145L14.4414 29.8555H12.873V26.7134L17.5835 21.9976L12.873 17.2871V14.145H14.4414L19.1572 18.8555V12.5713H22.2993V18.8555L27.0151 14.145H28.5835V17.2871L23.873 21.9976L28.5835 26.7134V29.8555H27.0151L22.2993 25.145V31.4292H19.1572Z"
			fill={palette.onPrimary}
		/>
		<path
			d="M42.0034 29.8579L43.5718 28.2896V25.1475L42.0034 23.5737H34.1455V29.8579H42.0034ZM42.0034 20.4316L43.5718 18.8579V15.7158L42.0034 14.1475H34.1455V20.4316H42.0034ZM31.0034 33V11H43.5718L46.7139 14.1475V20.4316L45.1455 22L46.7139 23.5737V29.8579L43.5718 33H31.0034Z"
			fill={palette.onPrimary}
		/>
		<path
			d="M49.1338 33V11H52.2759V20.4316L61.7022 11H64.8442V12.5737L55.418 22L64.8442 31.4316V33H61.7022L52.2759 23.5737V33H49.1338Z"
			fill={palette.onPrimary}
		/>
		<path
			d="M76.6904 29.8579L79.8325 26.7158V17.2896L76.6904 14.1475H70.4062V29.8579H76.6904ZM67.2642 33V11H78.2642L82.9746 15.7158V28.2896L78.2642 33H67.2642Z"
			fill={palette.onPrimary}
		/>
		<path
			d="M96.395 20.4316L97.9634 18.8579V15.7158L96.395 14.1475H88.5371V20.4316H96.395ZM85.395 33V11H97.9634L101.105 14.1475V20.4316L97.9634 23.5737H97.1792L101.105 31.4316V33H97.9634L93.2476 23.5737H88.5371V33H85.395Z"
			fill={palette.onPrimary}
		/>
	</svg>
);

export const B_KDR = (
	<svg width="113" height="44" viewBox="0 0 113 44" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect width="112" height="44" transform="translate(0.989258)" fill={palette.Primary} />
		<path
			d="M23.873 29.8579L25.4414 28.2896V25.1475L23.873 23.5737H16.0151V29.8579H23.873ZM23.873 20.4316L25.4414 18.8579V15.7158L23.873 14.1475H16.0151V20.4316H23.873ZM12.873 33V11H25.4414L28.5835 14.1475V20.4316L27.0151 22L28.5835 23.5737V29.8579L25.4414 33H12.873Z"
			fill={palette.onPrimary}
		/>
		<path
			d="M37.2876 31.4292V25.145L32.5718 29.8555H31.0034V26.7134L35.7139 21.9976L31.0034 17.2871V14.145H32.5718L37.2876 18.8555V12.5713H40.4297V18.8555L45.1455 14.145H46.7139V17.2871L42.0034 21.9976L46.7139 26.7134V29.8555H45.1455L40.4297 25.145V31.4292H37.2876Z"
			fill={palette.onPrimary}
		/>
		<path
			d="M49.1338 33V11H52.2759V20.4316L61.7022 11H64.8442V12.5737L55.418 22L64.8442 31.4316V33H61.7022L52.2759 23.5737V33H49.1338Z"
			fill={palette.onPrimary}
		/>
		<path
			d="M76.6904 29.8579L79.8325 26.7158V17.2896L76.6904 14.1475H70.4062V29.8579H76.6904ZM67.2642 33V11H78.2642L82.9746 15.7158V28.2896L78.2642 33H67.2642Z"
			fill={palette.onPrimary}
		/>
		<path
			d="M96.395 20.4316L97.9634 18.8579V15.7158L96.395 14.1475H88.5371V20.4316H96.395ZM85.395 33V11H97.9634L101.105 14.1475V20.4316L97.9634 23.5737H97.1792L101.105 31.4316V33H97.9634L93.2476 23.5737H88.5371V33H85.395Z"
			fill={palette.onPrimary}
		/>
	</svg>
);

export const BK_DR = (
	<svg width="113" height="44" viewBox="0 0 113 44" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect width="112" height="44" transform="translate(0.989258)" fill={palette.Primary} />
		<path
			d="M23.873 29.8579L25.4414 28.2896V25.1475L23.873 23.5737H16.0151V29.8579H23.873ZM23.873 20.4316L25.4414 18.8579V15.7158L23.873 14.1475H16.0151V20.4316H23.873ZM12.873 33V11H25.4414L28.5835 14.1475V20.4316L27.0151 22L28.5835 23.5737V29.8579L25.4414 33H12.873Z"
			fill={palette.onPrimary}
		/>
		<path
			d="M31.0034 33V11H34.1455V20.4316L43.5718 11H46.7139V12.5737L37.2876 22L46.7139 31.4316V33H43.5718L34.1455 23.5737V33H31.0034Z"
			fill={palette.onPrimary}
		/>
		<path
			d="M55.418 31.4292V25.145L50.7021 29.8555H49.1338V26.7134L53.8442 21.9976L49.1338 17.2871V14.145H50.7021L55.418 18.8555V12.5713H58.5601V18.8555L63.2759 14.145H64.8442V17.2871L60.1338 21.9976L64.8442 26.7134V29.8555H63.2759L58.5601 25.145V31.4292H55.418Z"
			fill={palette.onPrimary}
		/>
		<path
			d="M76.6904 29.8579L79.8325 26.7158V17.2896L76.6904 14.1475H70.4062V29.8579H76.6904ZM67.2642 33V11H78.2642L82.9746 15.7158V28.2896L78.2642 33H67.2642Z"
			fill={palette.onPrimary}
		/>
		<path
			d="M96.395 20.4316L97.9634 18.8579V15.7158L96.395 14.1475H88.5371V20.4316H96.395ZM85.395 33V11H97.9634L101.105 14.1475V20.4316L97.9634 23.5737H97.1792L101.105 31.4316V33H97.9634L93.2476 23.5737H88.5371V33H85.395Z"
			fill={palette.onPrimary}
		/>
	</svg>
);

export const BKD_R = (
	<svg width="113" height="44" viewBox="0 0 113 44" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect width="112" height="44" transform="translate(0.989258)" fill={palette.Primary} />
		<path
			d="M23.873 29.8579L25.4414 28.2896V25.1475L23.873 23.5737H16.0151V29.8579H23.873ZM23.873 20.4316L25.4414 18.8579V15.7158L23.873 14.1475H16.0151V20.4316H23.873ZM12.873 33V11H25.4414L28.5835 14.1475V20.4316L27.0151 22L28.5835 23.5737V29.8579L25.4414 33H12.873Z"
			fill={palette.onPrimary}
		/>
		<path
			d="M31.0034 33V11H34.1455V20.4316L43.5718 11H46.7139V12.5737L37.2876 22L46.7139 31.4316V33H43.5718L34.1455 23.5737V33H31.0034Z"
			fill={palette.onPrimary}
		/>
		<path
			d="M58.5601 29.8579L61.7022 26.7158V17.2896L58.5601 14.1475H52.2759V29.8579H58.5601ZM49.1338 33V11H60.1338L64.8442 15.7158V28.2896L60.1338 33H49.1338Z"
			fill={palette.onPrimary}
		/>
		<path
			d="M73.5488 31.4292V25.145L68.833 29.8555H67.2646V26.7134L71.9751 21.9976L67.2646 17.2871V14.145H68.833L73.5488 18.8555V12.5713H76.6909V18.8555L81.4067 14.145H82.9751V17.2871L78.2646 21.9976L82.9751 26.7134V29.8555H81.4067L76.6909 25.145V31.4292H73.5488Z"
			fill={palette.onPrimary}
		/>
		<path
			d="M96.395 20.4316L97.9634 18.8579V15.7158L96.395 14.1475H88.5371V20.4316H96.395ZM85.395 33V11H97.9634L101.105 14.1475V20.4316L97.9634 23.5737H97.1792L101.105 31.4316V33H97.9634L93.2476 23.5737H88.5371V33H85.395Z"
			fill={palette.onPrimary}
		/>
	</svg>
);

export const BKDR_ = (
	<svg width="113" height="44" viewBox="0 0 113 44" fill="none" xmlns="http://www.w3.org/2000/svg">
		<rect width="112" height="44" transform="translate(0.989258)" fill={palette.Primary} />
		<path
			d="M23.873 29.8579L25.4414 28.2896V25.1475L23.873 23.5737H16.0151V29.8579H23.873ZM23.873 20.4316L25.4414 18.8579V15.7158L23.873 14.1475H16.0151V20.4316H23.873ZM12.873 33V11H25.4414L28.5835 14.1475V20.4316L27.0151 22L28.5835 23.5737V29.8579L25.4414 33H12.873Z"
			fill={palette.onPrimary}
		/>
		<path
			d="M31.0034 33V11H34.1455V20.4316L43.5718 11H46.7139V12.5737L37.2876 22L46.7139 31.4316V33H43.5718L34.1455 23.5737V33H31.0034Z"
			fill={palette.onPrimary}
		/>
		<path
			d="M58.5601 29.8579L61.7022 26.7158V17.2896L58.5601 14.1475H52.2759V29.8579H58.5601ZM49.1338 33V11H60.1338L64.8442 15.7158V28.2896L60.1338 33H49.1338Z"
			fill={palette.onPrimary}
		/>
		<path
			d="M78.2646 20.4316L79.833 18.8579V15.7158L78.2646 14.1475H70.4067V20.4316H78.2646ZM67.2646 33V11H79.833L82.9751 14.1475V20.4316L79.833 23.5737H79.0488L82.9751 31.4316V33H79.833L75.1172 23.5737H70.4067V33H67.2646Z"
			fill={palette.onPrimary}
		/>
		<path
			d="M91.6792 31.4292V25.145L86.9634 29.8555H85.395V26.7134L90.1055 21.9976L85.395 17.2871V14.145H86.9634L91.6792 18.8555V12.5713H94.8213V18.8555L99.5371 14.145H101.105V17.2871L96.395 21.9976L101.105 26.7134V29.8555H99.5371L94.8213 25.145V31.4292H91.6792Z"
			fill={palette.onPrimary}
		/>
	</svg>
);
