import { SvgIcon, SvgIconProps } from "@mui/material";

export default function UserXIcon(props: SvgIconProps) {
	return (
		<SvgIcon data-testid="BKDRUserXIcon" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
			<path
				d="M7 6C7 3.23858 9.23858 1 12 1C14.7614 1 17 3.23858 17 6C17 8.76142 14.7614 11 12 11C9.23858 11 7 8.76142 7 6Z"
				fill="currentColor"
			/>
			<path
				d="M2 22C2 16.4772 6.47715 12 12 12C13.669 12 15.2425 12.4089 16.6258 13.1319L12.7578 17L15.2578 19.5L12.7578 22L13.7578 23H2V22Z"
				fill="currentColor"
			/>
			<path
				d="M19.5002 18.0858L17.0002 15.5858L15.5859 17L18.0859 19.5L15.5859 22L17.0002 23.4142L19.5002 20.9142L22.0002 23.4142L23.4144 22L20.9144 19.5L23.4144 17L22.0002 15.5858L19.5002 18.0858Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
}
