import { updateLocationRandomization } from "@api/profile";
import { refreshOwnUser } from "@feature/accountRegisterModel";
import { firstValueFrom } from "rxjs";
import locationSource from "./locationSource";

export async function setLocationRandomization(randomization: number) {
	const locationwithSource = await firstValueFrom(locationSource.locationWithSourceObservable);
	await updateLocationRandomization({
		location_randomization: randomization,
		lng: locationwithSource?.lng,
		lat: locationwithSource?.lat,
		source: locationwithSource?.source ?? undefined,
	});
	await refreshOwnUser();
}
