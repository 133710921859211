import Delayed from "@components/Delayed";
import { Box } from "@mui/material";
import constants from "@styles/constants";
import { useTranslation } from "next-i18next";
import { useState } from "react";
import { useInterval } from "react-timers-hooks";
import { _BKDR, B_KDR, BK_DR, BKD_R, BKDR_ } from "./BKDRLogo";

const logoVariants = [BKDR_, _BKDR, B_KDR, BK_DR, BKD_R];

export default function LoadingdMapView() {
	const { t } = useTranslation("common");
	const [logoIndex, setLogoIndex] = useState(0);

	useInterval(() => {
		setLogoIndex((prev) => (prev + 1) % logoVariants.length);
	}, 1000);

	return (
		<Delayed>
			<Box
				sx={{
					position: "absolute",
					inset: 0,
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
					top: `${constants.headerHeight}px`,
				}}
			>
				<>
					{logoVariants[logoIndex]}
					<p className="sr-only">{t("loading")}</p>
				</>
			</Box>
		</Delayed>
	);
}
