import { LinkButton } from "@components/Button";
import UserXIcon from "@components/icons/UserXIcon";
import vcrOsdMono from "@lib/fonts/vcrOsdMono";
import { Box, Typography } from "@mui/material";
import constants from "@styles/constants";
import palette from "@styles/palette";
import { useTranslation } from "next-i18next";

export default function AnonModeExplainer() {
	const { t } = useTranslation();

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				height: "100%",
				width: "100%",
				maxWidth: `${constants.dialogMaxWidth}px`,
				margin: "0 auto",
				padding: `${constants.headerHeight + 20}px 20px 40px`,
				gap: "20px",
				"& h2": {
					fontSize: "24px",
					margin: 0,
					fontFamily: vcrOsdMono.style.fontFamily,
					padding: "0px",
					fontWeight: "400",
				},
			}}
		>
			<h2>{t("anon_mode.info_modal_header")}</h2>

			<Typography>{t("anon_mode.explainer_add_photo_helper")}</Typography>
			<Typography>{t("anon_mode.explainer_content_deletion_helper")}</Typography>

			<UserXIcon sx={{ color: palette.Primary, width: "160px", height: "160px", margin: "auto" }} />

			<LinkButton to="/map" variant="primary" size="lg" sx={{ width: "100%" }}>
				{t("anon_mode.go_to_map")}
			</LinkButton>
		</Box>
	);
}
