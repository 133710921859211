import { featureGateObservable } from "@feature/featureFlags/featureConfigModel";
import { LocationStatus } from "@lib/models";
import replay from "@lib/util/frp/replay";
import { combineLatest, map, NEVER, Observable, switchMap } from "rxjs";
import locationSource from "./locationSource";
import { ipOverThresholdObservable } from "./sources/ipLocation";
import preciseLocation from "./sources/preciseLocation";

const { preciseLocationStateObservable } = preciseLocation;

export const locationStatusObservable: Observable<LocationStatus> = featureGateObservable("location_accuracy")
	.pipe(
		switchMap((locationAccuracyEnabled) => {
			if (locationAccuracyEnabled) {
				return combineLatest({
					ipLocationOverThreshhold: ipOverThresholdObservable,
					hasLocation: locationSource.hasLocationObservable,
					preciseLocationState: preciseLocationStateObservable,
				});
			}
			return NEVER;
		}),
	)
	.pipe(
		map(
			({
				ipLocationOverThreshhold,
				hasLocation,
				preciseLocationState: { error, permissionState },
			}): LocationStatus => ({
				precisePermissionState: permissionState,
				error,
				hasLocation,
				ipLocationOverThreshhold,
			}),
		),
	)
	.pipe(replay());
