import { clearLocation, updateLocation } from "@api/profile";
import { refreshOwnUser } from "@feature/accountRegisterModel";
import { isUserObservable } from "@lib/auth/authModel";
import { LocationWithSource } from "@lib/models";
import replay from "@lib/util/frp/replay";
import reportError from "@lib/util/reportError";
import { combineLatest, distinctUntilChanged, from, NEVER, Observable, of, startWith, switchMap } from "rxjs";
import { ipLocationObservable, ipOverThresholdObservable } from "./sources/ipLocation";
import preciseLocation from "./sources/preciseLocation";

const { preciseLocationStateObservable, preciseLocationObservable } = preciseLocation;

const locationWithSourceObservable = combineLatest({
	preciseLocationState: preciseLocationStateObservable,
	ipOverThreshold: ipOverThresholdObservable,
}).pipe(
	switchMap(({ preciseLocationState: { permissionState }, ipOverThreshold }) => {
		if (permissionState === "denied" && !ipOverThreshold) {
			return ipLocationObservable;
		}
		// request location if permissions are granted or need to be prompted
		if (permissionState === "granted" || permissionState === "prompt") {
			return preciseLocationObservable;
		}
		return of(null);
	}),
);

async function updateServer(locationWithSource: LocationWithSource | null) {
	const hasLocation = locationWithSource != null;
	if (hasLocation) {
		await updateLocation(locationWithSource);
	} else {
		await clearLocation();
	}
	await refreshOwnUser();
	return hasLocation;
}

const hasLocationObservable: Observable<boolean> = isUserObservable
	.pipe(distinctUntilChanged())
	.pipe(
		switchMap((isUser) => {
			if (isUser) {
				return locationWithSourceObservable;
			}
			return NEVER;
		}),
	)
	.pipe(
		switchMap((locationWithSource) => {
			return from(
				updateServer(locationWithSource).catch((error: unknown) => {
					reportError(error as Error);
					return false;
				}),
			);
		}),
	)
	.pipe(startWith(false))
	.pipe(distinctUntilChanged())
	.pipe(replay());

// exported as an object so that these names aren't added to
// automatic import options
export default {
	locationWithSourceObservable,
	hasLocationObservable,
};

let subscribed = false;
export function subscribeGlobalLocationUpdate() {
	if (subscribed) {
		throw new Error("Already active! Called subscribeGlobalLocationUpdate() again unexpectedly!");
	}
	subscribed = true;
	const subscription = hasLocationObservable.subscribe(() => {});
	return () => {
		subscription.unsubscribe();
		subscribed = false;
	};
}
