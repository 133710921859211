import Alert from "@components/Alert";
import { Button } from "@components/Button";
import { useTranslation } from "next-i18next";
import LocationInaccurateModal from "./LocationInaccurateModal";

export default function LocationInaccurateToast() {
	const { t } = useTranslation("common");

	return (
		<Alert
			role="presentation"
			title={t("location_request.location_inaccurate")}
			body={t("location_request.location_inaccurate_body")}
			severity="error"
			button={
				<LocationInaccurateModal
					renderButton={(onOpen) => (
						<Button variant="primary ghost" onClick={onOpen}>
							{t("location_request.location_inaccurate_button")}
						</Button>
					)}
				/>
			}
		/>
	);
}
