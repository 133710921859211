import { accountRegisterObservable } from "@feature/accountRegisterModel";
import { featureGateObservable } from "@feature/featureFlags/featureConfigModel";
import { isUserObservable } from "@lib/auth/authModel";
import { AccountRegister, Location, LocationWithNullableSource, UserAccount } from "@lib/models";
import {
	BehaviorSubject,
	NEVER,
	Observable,
	ReplaySubject,
	distinctUntilChanged,
	filter,
	map,
	of,
	share,
	switchMap,
} from "rxjs";
import { locationStatusObservable } from "./locationStatusModel";

export const locationOverrideBehavior = new BehaviorSubject<null | Location>(null);

export function setLocationOverride(location: null | Location) {
	locationOverrideBehavior.next(location);
}

function extendsLocation<T extends { lng: null | number; lat: null | number }>(obj: T): obj is T & Location {
	return typeof obj.lng === "number" && typeof obj.lat === "number";
}

const ownUserLocationObservable: Observable<LocationWithNullableSource | null> = featureGateObservable(
	"location_accuracy",
)
	.pipe(
		switchMap((locationAccuracyEnabled) => {
			if (locationAccuracyEnabled) {
				return locationStatusObservable.pipe(map(({ hasLocation }) => hasLocation));
			}
			return of(true);
		}),
	)
	.pipe(
		switchMap((hasLocation) => {
			if (hasLocation) {
				return accountRegisterObservable;
			}
			return NEVER;
		}),
	)
	.pipe(
		filter((accountRegister): accountRegister is AccountRegister & { user: UserAccount } => {
			return accountRegister.user != null;
		}),
	)
	.pipe(map((accountRegister) => accountRegister.user))
	.pipe(
		map((ownUser): LocationWithNullableSource | null => {
			if (extendsLocation(ownUser)) {
				return {
					lng: ownUser.lng,
					lat: ownUser.lat,
					source: ownUser.location_source,
				};
			}
			return null;
		}),
	);

export const locationObservable: Observable<LocationWithNullableSource | null> = isUserObservable
	.pipe(filter((isUser) => isUser))
	.pipe(
		switchMap(() => {
			return locationOverrideBehavior;
		}),
	)
	.pipe(
		switchMap((override) => {
			if (override == null) {
				return ownUserLocationObservable;
			}
			return of({
				...override,
				source: null,
			});
		}),
	)
	.pipe(
		distinctUntilChanged((prev: LocationWithNullableSource | null, current: LocationWithNullableSource | null) => {
			if (current === prev) {
				return true;
			}
			if (current === null || prev === null) {
				return false;
			}
			return prev.lng === current.lng && prev.lat === current.lat;
		}),
	)
	.pipe(share({ connector: () => new ReplaySubject(1) }));
