import useUser from "@hooks/useUser";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AnonModeExplainer from "./AnonModeExplainer";
import AnonSignUp from "./anonSignUp/AnonSignUp";

export default function AnonEntry() {
	const authUser = useUser();
	const navigate = useNavigate();

	useEffect(() => {
		if (authUser?.is_anon === false) {
			navigate("/map", { replace: true });
		}
	}, [authUser?.is_anon, navigate]);

	if (authUser?.is_anon === false) {
		return null;
	}

	if (authUser?.is_anon) {
		return <AnonModeExplainer />;
	}
	return <AnonSignUp />;
}
