import useFeatureGate from "@feature/featureFlags/useFeatureGate";
import { subscribeGlobalLocationUpdate } from "@feature/location/locationSource";
import subscribeToUpdateMapCenterFromLocationChange from "@feature/map/subscribeToUpdateMapCenterFromLocationChange";
import { Component } from "react";

class MountGlobalModelsWithLocationAccuracy extends Component {
	unsubscribe?: () => void;
	componentDidMount(): void {
		const unsubscribeFromUpdateMapCenterFromLocationChange = subscribeToUpdateMapCenterFromLocationChange();
		const unsubscribeGlobalLocationUpdate = subscribeGlobalLocationUpdate();
		this.unsubscribe = () => {
			unsubscribeGlobalLocationUpdate();
			unsubscribeFromUpdateMapCenterFromLocationChange();
		};
	}
	componentWillUnmount(): void {
		this.unsubscribe?.();
	}
	render() {
		return null;
	}
}

export function MountGlobalModelsWithAccountRegister() {
	const locationAccuracyEnabled = useFeatureGate("location_accuracy");

	if (locationAccuracyEnabled) {
		return <MountGlobalModelsWithLocationAccuracy />;
	}
	return null;
}
