import ITMCEntryController from "@feature/itmc/entry/ITMCEntryController";
import useUser from "@hooks/useUser";
import assertType from "@lib/util/assertType";
import { Box } from "@mui/material";
import constants from "@styles/constants";
import mapboxgl, { LngLatLike } from "mapbox-gl";
import CenterControl from "../CenterControl";
import ExplorePopularCitiesControl from "../ExplorePopularCitiesControl";

export type BottomRightMapControlsProps = {
	mapbox: mapboxgl.Map;
	center: LngLatLike;
};

export default function BottomRightMapControls({ mapbox, center }: BottomRightMapControlsProps) {
	const user = assertType(useUser());
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "flex-end",
				position: "absolute",
				gap: "10px",
				bottom: constants.itmcButtonBottomOffset,
				right: 0,
				pointerEvents: "none",
				"> *": {
					pointerEvents: "all",
				},
			}}
		>
			<Box sx={{ gap: 0, marginBottom: "40px" }}>
				{user.is_admin === true ? <ExplorePopularCitiesControl source="map" /> : null}
				<CenterControl mapbox={mapbox} center={center} />
			</Box>
			<ITMCEntryController source="map" />
		</Box>
	);
}
